// invitation flag feature
export const ENABLE_SHIFT_TIME_RECEPTION = false;
export const ENABLE_ANNOUNCEMENT_MODE = true;
export const ENABLE_PARTNER_MODE = true;

// Giftcard flag feature
export const ENABLE_SEND_GIFT = false;
export const ENABLE_BANK_TRANSFER = true;
export const ENABLE_GIFT_CONFIRMATION = true;

// generator link flag feature
export const DISABLE_CUSTOM_LINK = false;
export const DISABLE_GENERATOR_INVITATION = false;
export const DISABLED_QR_GENERATOR_IN_PRODUCTION = true;
export const ENABLE_GUEST_LIST = true;

// Global feature invitation
export const DISABLED_MUSIC_IN_DEVELOPMENT = true;
export const MUSLIM_INVITATION = false; // change to `true` if you want to use muslim wording
export const DEFAULT_LANGUAGE = 'id'; // change to `en` if you want to set default to English
export const ENABLE_MULTI_LANGUAGE = false;
export const ENABLE_QR_INVITATION = true;
export const ENABLE_MUSIC_BACKSOUND = true;

export const ENABLE_LIVE_STREAMING = true;  //LIVE STREAMING
export const ENABLE_GIFT_CARD = true;   //GIFTCARD
export const ENABLE_DARKMODE_NOTIFICATION = true;

export const ENABLE_LOVE_STORY = false; //LOVE STORY
export const ENABLE_LOVE_STORY_CONTENT = false; // change to `true` if you want to add button to content
export const ENABLE_RSVP = true; //RSVP
export const ENABLE_COVID_PROTOCOL = false;
export const ENABLE_GIF_COUNTDOWN = true; //VIDEO GIF
export const ENABLE_COLLABORATION = false;  //COLLABORATION
export const ENABLE_GUEST_PERSONALIZATION = true; // change to `en` if you want to set personalization guest
export const ENABLE_IG_FILTER = true; //FILTER INSTAGRAM
